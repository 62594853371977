<template>
  <nav>
    <v-app-bar flat app>
      <v-app-bar-nav-icon @click="drawer = !drawer" class="grey--text"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase grey--text">
        <span class="font-weight-light">TSI</span>
        <span>EYE</span>
      </v-toolbar-title>
      <div style="margin-left: 2em; color: darkred" v-if="conf().anotherAPI">API: {{ conf().baseURL }}</div>
      <v-spacer />
      <v-menu offset-y bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="grey--text" text v-bind="attrs" v-on="on">
            <v-icon left>mdi-account</v-icon>
            <span class="font-weight-light">{{ user.username }}</span>
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-divider class="mx-2"></v-divider>
          <v-list-item @click="logoutUser">
            <v-list-item-content>
              <v-list-item-title>Выход</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app>
      <v-list dense nav class="d-flex flex-column">
        <template v-for="(group, index) in userLinkGroups">
          <v-divider v-if="index > 0" :key="index" />
          <v-list-item v-for="link in group" :key="link.label" router :to="link.url">
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ link.label }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Config from '@/config/config'
export default {
  data() {
    return {
      drawer: null,
      linkGroups: [
        [
          {
            label: 'Проекты',
            icon: 'mdi-folder-outline',
            url: '/project'
          }
        ],
        [{ label: 'Пользователи', icon: 'mdi-account-outline', url: '/user' }]
      ]
    }
  },
  computed: {
    userLinkGroups() {
      return this.isAdminLoggedIn() ? this.linkGroups : new Array(this.linkGroups[0])
    },
    ...mapGetters('auth', ['user'])
  },
  methods: {
    isAdminLoggedIn() {
      return this.user && this.user.id === 1
    },
    logoutUser() {
      this.logout()
      this.$router.push('/login')
    },
    conf() {
      return Config
    },
    ...mapActions({ logout: 'auth/logout' })
  }
}
</script>
