<template>
  <div class="home">
    <NavBar />
    <v-content class="mx-4 mt-4">
      <router-view :key="$route.fullPath" />
    </v-content>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar'
export default {
  components: {
    NavBar
  }
}
</script>

<style></style>
